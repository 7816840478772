<script setup>
import ArrowSvg from '../svgs/right-arrow.svg';
import { computed, ref } from 'vue';
import { isBlank } from '@/utils/default';

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: 0
  },
  length: {
    type: Number,
    default: 0
  },
  boxSize: {
    type: String,
    default: 'small'
  }
});

const backgroundColor = ref('bg-white');
const textColor = ref('text-black');
const colorSettings = computed(() => {
  if (props?.item.BackgroundColor === 'Red') backgroundColor.value = 'bg-secondary';
  if (props?.item.BackgroundColor === 'Yellow') backgroundColor.value = 'bg-primary';
  if (props?.item.TextColor === 'White') textColor.value = 'text-white';

  return `${backgroundColor.value} ${textColor.value}`;
});

const svgColor = ref('text-black');
const svgColorSetting = computed(() => {
  if (props?.item.ArrowColor === 'Red') svgColor.value = 'text-secondary';
  if (props?.item.ArrowColor === 'Yellow') svgColor.value = 'text-primary';
  if (props?.item.ArrowColor === 'White') svgColor.value = 'text-white';

  return svgColor.value;
});

const titleBackgroundColor = ref('white');
const titleBackgroundOpacity = ref(0.4);
const textBackgroundColorSetting = computed(() => {
  if (props?.item.TitleBackgroundColor === 'Red') titleBackgroundColor.value = 'secondary';
  if (props?.item.TitleBackgroundColor === 'Yellow') titleBackgroundColor.value = 'primary';
  if (props?.item.TitleBackgroundColor === 'White') titleBackgroundColor.value = 'white';
  if (props?.item.TitleBackgroundColor === 'Black') titleBackgroundColor.value = 'black';

  if (props?.item.TitleBackgroundOpacity) return (titleBackgroundOpacity.value = props?.item.TitleBackgroundOpacity);
  return titleBackgroundColor.value;
});
const hasTitleBackgroundColor = computed(() => {
  return !(isBlank(props.item.TitleBackgroundColor) || props.item.TitleBackgroundColor === 'None');
});

const isBig = computed(() => {
  return props?.boxSize === 'big';
});

const isMedium = computed(() => {
  return props?.boxSize === 'medium';
});

const isSmall = computed(() => {
  return props?.boxSize === 'small';
});
</script>

<template>
  <Card class="grid-item d-flex flex-column" :class="isBig ? `first-item -col-span-12 -col-span-md-6 ${colorSettings}` : isMedium ? `-col-span-12 -col-span-md-6 ${colorSettings}` : `-col-span-12 -col-span-sm-6 -col-span-md-3 ${colorSettings}`">
    <div class="content flex-grow-1 w-100" :class="isBig && 'grid'" :style="props?.item.BackgroundImage && `background-image: url('${props?.item.BackgroundImage.url}');`">
      <div v-if="isBig" class="h-100 d-flex flex-column align-start grid-item -col-span-12 -col-span-sm-8">
        <h2 v-if="props?.item.Title" :class="isBig ? 'h1' : 'h4'" v-html="props?.item.Title" />
      </div>

      <a v-if="!isBig" :href="props?.item.Link" class="h-100 w-100" :class="isMedium && 'grid'" :aria-label="`Link that goes to ${props?.item.LinkLabel}`">
        <div class="h-100 d-flex flex-column align-content-end max-h-390px" :class="isMedium ? 'grid-item -col-span-12 -col-span-sm-6' : ''">
          <div :class="hasTitleBackgroundColor ? `bg-title-color-${textBackgroundColorSetting} mx-n5 mt-n3 mb-1 px-5 py-3 rounded-lg w-auto` : ''">
            <h2 v-if="props?.item.Title" class="-no-margin no-opacity w-100" :class="isBig ? 'h1' : 'h4'" v-html="props?.item.Title" />
          </div>
          <VImg v-if="props?.item.Image?.url" class="w-100 my-4 flex-shrink-1" aspect-ratio="1/1" :src="props?.item.Image.url" :alt="props?.item.Image.alternativeText" />
          <div class="d-flex flex-grow-1 flex-column align-content-end">
            <div class="flex-grow-1 d-flex align-end">
              <p v-if="props?.item.Description" class="paragraph mb-0" v-html="props?.item.Description" />
            </div>
            <ArrowSvg class="w-50px mt-4" :class="`${svgColorSetting}`" />
          </div>
        </div>
      </a>
    </div>
    <div v-if="isBig" class="bottom-bar d-flex flex-lg-row flex-column">
      <VImg v-if="props?.item.Image?.url" cover :aspect-ratio="1 / 1" class="rounded-circle first-image" :src="props?.item.Image.url" />

      <div class="flex-grow-1 pl-0 pt-lg-8 pb-8 pb-lg-0">
        <p v-if="props?.item.Description" class="paragraph" v-html="props?.item.Description" />
        <Button class="bg-secondary w-100 mb-6" color="white" :href="props?.item.Link">{{ props?.item.LinkLabel }}</Button>
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.v-card:not(.first-item) {
  transition:
    transform 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;

  svg {
    transition: width 0.5s ease-in-out;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0.4) !important;

    svg {
      width: 60px !important;
    }
  }
}

.bg-title-color-white {
  background-color: rgba($color-white, 0.4);
}
.bg-title-color-black {
  background-color: rgba($color-black, 0.4);
}
.bg-title-color-primary {
  background-color: rgba($color-primary, 0.4);
}
.bg-title-color-secondary {
  background-color: rgba($color-secondary, 0.4);
}

.grid-item {
  height: 100%;

  .content {
    padding: 24px 32px 32px;
    background-size: cover;
    background-position: center;
    flex-grow: 1;

    @include is-screen-lg {
      padding: 32px 40px 40px;
    }
  }
}
.bottom-bar {
  padding: 0 32px;

  @include is-screen-lg {
    padding: 0 40px;
  }
}
.first-item {
  @include is-screen-md {
    grid-row: span 2;
  }
}
.first-image {
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
  margin-bottom: -30px;

  @include is-screen-lg {
    transform: translateY(-32px);
    width: 190px;
    height: 190px;
    margin-bottom: 0;
  }
}
</style>
