<script setup>
import GridItem from '@Components/GridItem.vue';

const props = defineProps({
  strapi: {
    type: Object,
    default: () => {}
  }
});

function cardSize(index, length) {
  if (index === 0) return 'big';

  if (isDoubleCard(index, length)) return 'medium';
  else return 'small';
}
function isDoubleCard(index, length) {
  return length === 3 || (length === 4 && index === 1);
}
</script>

<template>
  <div v-if="strapi?.GridItem?.length > 0" class="py-15 container">
    <div class="grid main-grid">
      <GridItem v-for="(item, index) in props?.strapi?.GridItem" :key="index" :boxSize="cardSize(index, props?.strapi?.GridItem.length)" :index="index" :item="item" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-grid {
  grid-auto-flow: dense;
  grid-auto-rows: 430px;

  @include is-screen-sm() {
    grid-auto-rows: 500px;
  }

  @include is-screen-md() {
    grid-auto-rows: 360px;
  }

  @include is-screen-lg() {
    grid-auto-rows: 450px;
  }
}
</style>
